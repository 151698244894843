import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyStart
        >
          <CFView textCenter column center w="100%" h="70vh">
            <CFView column center>
              <CFImage src={heroText} w="90%" alt="Promotions" />
            </CFView>
            <CFView mt="30px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          relative
          column
          center
        >
          <CFView w="100%" column center>
            <CFView column center mt="15px">
              <CFImage src={heroText} w="50%" alt="Promotions" />
            </CFView>
            <CFView mt="50px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
